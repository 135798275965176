import "@fontsource/crimson-pro/200.css"
import "@fontsource/karla/200.css"
import "@fontsource/karla/300.css"
import "@fontsource/karla/400.css"
import "@fontsource/karla/600.css"
import React from "react"
import NewsProvider from "./src/contexts/News/context"

export const wrapRootElement = ({ element }) => (
  <NewsProvider>{element}</NewsProvider>
)
