import { NewsState } from "./news"
import { NEWS_ACTION_TYPES } from "./actions"
import { sortBy } from "lodash"

export const initialState = {
  numberShowing: 10,
  currentFilter: { id: "0", title: "all" },
}

export const newsReducer = (
  state: NewsState,
  action: { type: NEWS_ACTION_TYPES; payload?: any }
) => {
  switch (action?.type) {
    case NEWS_ACTION_TYPES.INCREASE_NUMBER_SHOWING:
      return { ...state, numberShowing: state.numberShowing + action?.payload }
    case NEWS_ACTION_TYPES.CHANGE_FILTER:
      return { ...state, currentFilter: action?.payload }
    case NEWS_ACTION_TYPES.SORT_BY_TOPIC: {
      return { ...state, isTopicSelected: action?.payload }
    }
    case NEWS_ACTION_TYPES.RESET:
      return initialState
  }
}
