import { createContext, Dispatch, FC, ReactNode, useReducer } from "react"
import { NewsState } from "./news"
import { initialState, newsReducer } from "./reducer"

export const NewsContext = createContext<[NewsState, Dispatch<any>]>([
  initialState,
  () => {},
])

type NewsProviderProps = {
  children: ReactNode
}
const NewsProvider: FC<NewsProviderProps> = ({ children }) => {
  const [newsState, dispatch] = useReducer(newsReducer, initialState)
  return (
    <NewsContext.Provider value={[newsState, dispatch]}>
      {children}
    </NewsContext.Provider>
  )
}

export default NewsProvider
