export enum NEWS_ACTION_TYPES {
  INCREASE_NUMBER_SHOWING,
  RESET,
  CHANGE_FILTER,
  SORT_BY_TOPIC,
}

const increaseNumberShowing = (by: number) => ({
  type: NEWS_ACTION_TYPES.INCREASE_NUMBER_SHOWING,
  payload: by,
})

const resetNewsState = () => ({
  type: NEWS_ACTION_TYPES.RESET,
})

const changeFilter = (filter: { id: string; title: string }) => ({
  type: NEWS_ACTION_TYPES.CHANGE_FILTER,
  payload: filter,
})

const setIsSortedByTopic = (field: string) => ({
  type: NEWS_ACTION_TYPES.SORT_BY_TOPIC,
  payload: field,
})

export const NewsActions = {
  increaseNumberShowing,
  resetNewsState,
  changeFilter,
  setIsSortedByTopic,
}
